import React from "react";
import Post from '../component/Post';
import { graphql } from 'gatsby';
import PrimaryLayout from '../layouts/PrimaryLayout';

export default ({data}) => {
  const { allWordpressPost } = data;
  return ( 
    <PrimaryLayout>
      {
        allWordpressPost.nodes.map((node) => {
          const { excerpt, title, slug, featured_media } = node;
          const imageAttributes = {
            source_url: featured_media ? featured_media.source_url : '/images/background.jpg',
            imageSlug: featured_media ? featured_media.imageSlug : 'default image"',
          }
          return (
            <Post 
              key={title}
              image={imageAttributes.source_url}
              alt={imageAttributes.imageSlug}
              title={title}
              excerpt={excerpt}
              slug={slug}
            />
          )
        })
      }
    </PrimaryLayout>
  )
}

export const query = () => graphql`
  {
    allWordpressPost {
      nodes {
        slug
        title
        excerpt
        featured_media {
          source_url
          imageSlug: slug
        }
      }
    }
  }
`
