import React from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
import SEO from '../component/SEO';

const PrimaryLayout = (props) => ( 
  <div>
    <SEO />
    <Header title="Home"/>
    <main>
      <div className="container">
        <div className="row justify-content-md">
          <div className="col-xs-6">
            {props.children}
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
)

export default PrimaryLayout;