import React from 'react';
import { Card, Button } from 'react-bootstrap';

const Post = ({title = "No Title", excerpt="No Content", image, slug, alt}) => {
  return (
    <div className="p-3">
      <Card>
        <Card.Img variant="top" src={image} alt={alt}/>
        <Card.Body>
          <Card.Title dangerouslySetInnerHTML={{__html: title}} />
          <Card.Text>
            <div dangerouslySetInnerHTML={{__html: excerpt}} />
          </Card.Text>
          <Button variant="primary" href={slug}>Go somewhere</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Post;